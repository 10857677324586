import { Box } from "@mui/material";
import React, { useEffect, useReducer, useState } from "react";

import { fetchUserAttributes } from "aws-amplify/auth";


import LeftTextArea from "./LeftTextArea";
import RightTextArea from "./RightTextArea";
import LanguageDropdown from "./LanguageDropdown";
import { toTitleCase } from "./Utils";
import { languagesFromData } from "./Variables";

import { post } from "aws-amplify/api";

// Custom hook for debouncing
function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

function getAvailableTranslationLanguages(sourceLanguage){
  let translationLanguages = languagesFromData.filter((lang) => lang.id === sourceLanguage)[0]?.availableto;
  let result = []
  if (translationLanguages){
    translationLanguages.forEach((lang) => {
      result.push({id: lang});
    });
  }
  return result;
}

export const getIdFromLangId = (langId) => {
  let id = languagesFromData.find(lang => lang.langId === langId)?.id;
  if(typeof(id)=="string"){
      return id;
  }
  return "";
}

const sourceLanguageIds = languagesFromData.reduce((acc,curr) => (acc.push(curr.id), acc), []);

function languageReducer(state, action){
  switch(action.type){
    case "SOURCE_LANGUAGE_CHANGE":
      if (action.val){
        let availableTranslations = getAvailableTranslationLanguages(action.val);
        let newTranslationLanguage = availableTranslations.length > 0 ? availableTranslations[0].id : "";
        if (availableTranslations.some(l => l.id === state.translationLanguage)){
          newTranslationLanguage = state.translationLanguage;
        };
        return {...state, sourceLanguage: action.val, translationLanguage: newTranslationLanguage, translationLanguageList: availableTranslations, swapLanguages: true };
      }
      return state;
    case "TRANSLATION_LANGUAGE_CHANGE":
      return {...state, translationLanguage: action.val, swapLanguages: true};
    case "ACTIVATE_LANGUAGE_DETECTION":
      return {...state, sourceLanguage: "", translationLanguage: "", translationLanguageList: [], swapLanguages: false};
    case "SWAP_LANGUAGES":
      let availableTranslations = getAvailableTranslationLanguages(state.translationLanguage);
      return {...state, sourceLanguage: state.translationLanguage, translationLanguage: state.sourceLanguage, translationLanguageList: availableTranslations};
    default:
      return state;
  }
}

function TextTranslate(props) {

  //useReducer
  const initialState = {
    sourceLanguage: "",
    translationLanguage: "",
    translationLanguageList: [],
    swapLanguages: false
  };

  const [language, languageDispatcher] = useReducer(languageReducer, initialState);

  const [sourceText, setSourceText] = useState("");
  const debouncedSourceText = useDebounce(sourceText, 800);
  const [translationText, setTranslationText] = useState("");

  const [detectLanguage, setDetectLanguage] = useState("detect");
  const [detectLanguageLoading, setDetectLanguageLoading] = useState(false);
  const [translateLoading, setTranslateLoading] = useState(false);

  const [requestId, setRequestId] = useState("");
  const [userEmailAddress, setUserEmailAddress] = useState("");
  const [userOrg, setUserOrg] = useState("");

  const [justSwappedLanguage, setJustSwappedLanguage] = useState(-1);

  useEffect(() => {
    if (sourceText === "") {
      setTranslationText("");
      setRequestId("");
      if(sourceLanguageIds.includes(detectLanguage)){
        setDetectLanguage("detect");
        languageDispatcher({type: "ACTIVATE_LANGUAGE_DETECTION"});
      }
    }
  }, [sourceText]);

  useEffect(() => {
    if(detectLanguage === "detect"){
      languageDispatcher({type: "ACTIVATE_LANGUAGE_DETECTION"});
      if (sourceText && debouncedSourceText){
        requestLanguageDetection(debouncedSourceText);
      }
    }else if(sourceLanguageIds.includes(detectLanguage)){
      languageDispatcher({val: detectLanguage, type: "SOURCE_LANGUAGE_CHANGE"});
    }
  }, [detectLanguage]);

  useEffect(() => {
    if (sourceText && debouncedSourceText){
      incrementSwapLanguage();
      if ( language.sourceLanguage !== "" && language.translationLanguage !== "" && justSwappedLanguage === -1 ){
        submitTextTranslation(language.sourceLanguage, language.translationLanguage, debouncedSourceText, userEmailAddress, userOrg);
      }
    }
  }, [language]);

  useEffect(() => {
    if (debouncedSourceText){
      if (detectLanguage !== ""){ //Autodetection is on
        const reqLangId = async()=> {
          let source_lang = language.sourceLanguage;
          const detected_lang = await requestLanguageDetection(debouncedSourceText);
          // if detected language is the same as the current source language, request translation. 
          if (source_lang === detected_lang && debouncedSourceText && language.sourceLanguage !== "" && language.translationLanguage !== "" ){
            submitTextTranslation(language.sourceLanguage, language.translationLanguage, debouncedSourceText, userEmailAddress, userOrg);
          }
          return detected_lang;
        }
        reqLangId();
      }
      incrementSwapLanguage();
      if (detectLanguage === "" && language.sourceLanguage !== "" && language.translationLanguage !== "" && justSwappedLanguage === -1){ //Autodetection is off
        submitTextTranslation(language.sourceLanguage, language.translationLanguage, debouncedSourceText, userEmailAddress, userOrg);
      }
    }
  }, [debouncedSourceText]);

  useEffect(() => {
    // Get user attributes
    handleFetchUserAttributes();
    makeDummyCallToApi();
  }, []);

  //Call to make sure the services are up by the time we want to translate
  async function makeDummyCallToApi(){
    const restOperation1 = post({
      apiName: "probatuaeusapi",
      path: "/languageid/detect-language",
      options: {
        body: {text: ""},
      },
    });
    await restOperation1.response;
    const restOperation2 = post({
      apiName: "probatuaeusapi",
      path: "/v1/api/translationtxt",
      options: {
        body: { from: "", to: "", user: "", workgroup: "", text: "" },
      },
    });
    await restOperation2.response;
  }

  async function handleFetchUserAttributes() {
    try {
      const userAttributes = await fetchUserAttributes();
      setUserEmailAddress(userAttributes.email);
      if (userAttributes.zoneinfo && userAttributes.zoneinfo !== "") {
        setUserOrg(toTitleCase(userAttributes.zoneinfo));
      }else{
        setUserOrg("vicomtech");
      }
    } catch (error) {
      // console.log(error);
      // TODO logout
    }
  }

  async function submitTextTranslation(languageFrom, languageTo, translationText, user, org) {
    try {
      const textTranslationRequest = { from: languageFrom, to: languageTo, user: user, workgroup: org, text: translationText };
      /*
      	FromLanguage    string `json:"from"`
        ToLanguage      string `json:"to"`
        TranslationText string `json:"text"`
        Workgroup       string `json:"workgroup"`
        UserEmail       string `json:"user"`
      */
      
      setTranslateLoading(true);

      const restOperation = post({
        apiName: "probatuaeusapi",
        path: "/v1/api/translationtxt",
        options: {
          body: textTranslationRequest,
        },
      });

      const { statusCode, body } = await restOperation.response;
      const bodyJson = await body.json();
      if (statusCode === 200 && bodyJson.success){
        // Set response text
        setTranslationText(bodyJson.message)
        setRequestId(bodyJson.id)
      }else{
        // Set response text as error message
        setTranslationText(bodyJson.attention)
        setRequestId(bodyJson.id)
      }
    } catch (err) {
      // Set response text
      setTranslationText("")
      setRequestId("")
    } finally {
      setTranslateLoading(false);
    }
  }

  async function requestLanguageDetection(sourceText) {
    try {
      setDetectLanguageLoading(true);
      const languageDetectionRequest = {text: sourceText};
      // Text string `json:"text"`

      const restOperation = post({
        apiName: "probatuaeusapi",
        path: "/languageid/detect-language",
        options: {
          body: languageDetectionRequest,
        },
      });

      const { statusCode, body } = await restOperation.response;
      const bodyJson = await body.json();
      if (statusCode === 200){
        const detected_lang_id = getIdFromLangId(bodyJson.prediction);
        if (detected_lang_id === ""){
          throw new Error("Unable to map LanguageId response to language")
        }
        setDetectLanguage(detected_lang_id);
        return detected_lang_id;
      }else{
        throw new Error("Status Code != 200")
      }
    } catch (err) {
      console.log(err);
      //if error, set basque as detected language
      setDetectLanguage("eu");
    } finally {
      setDetectLanguageLoading(false);
    }
  }

  function incrementSwapLanguage(){
    if(sourceText !== '' && justSwappedLanguage+1 === 1){
      setJustSwappedLanguage(-1);
    }else if(justSwappedLanguage >= 0){
      setJustSwappedLanguage(justSwappedLanguage+1)
    }
  }

  const handleSetSourceLanguage = (lang) => {
    languageDispatcher({val: lang, type: "SOURCE_LANGUAGE_CHANGE"});
    setDetectLanguage("");
  }

  const handleSetTranslationLanguage = (lang) => {
    languageDispatcher({val: lang, type: "TRANSLATION_LANGUAGE_CHANGE"});
  }

  const handleActivateLanguageDetection = () => {
      languageDispatcher({type: "ACTIVATE_LANGUAGE_DETECTION"});
      setDetectLanguage("detect");
      setTranslationText("");
  }

  const handleLanguageSwap = () => {
    languageDispatcher({type: "SWAP_LANGUAGES"});
    if (sourceText !== '') {
      setJustSwappedLanguage(0);
    }
    setDetectLanguage("");
    setSourceText(translationText);
    setTranslationText(sourceText);
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          flexDirection: { xs: "column", md: "row" },
          m: "1rem",
          gap: 2
        }}
      >
        <Box sx={{ width: { xs: "100%", md: "50%" } }}>
          <LanguageDropdown currentLanguage={language.sourceLanguage} setLanguage={handleSetSourceLanguage} languages={languagesFromData} autolang_available={true} autolang={detectLanguage} setAutolang={handleActivateLanguageDetection} autolang_loading={detectLanguageLoading} showLangSwap={true} langSwap={language.swapLanguages} handleLangSwap={handleLanguageSwap} swapToLang={language.translationLanguage}/>
          <LeftTextArea enteredText={sourceText} setEnteredText={setSourceText} />
        </Box>
        <Box sx={{ width: { xs: "100%", md: "50%" } }}>
          <LanguageDropdown currentLanguage={language.translationLanguage} setLanguage={handleSetTranslationLanguage} languages={language.translationLanguageList} />
          <RightTextArea fetchedText={translationText} setFetchedText={setTranslationText} requestIdValue={requestId} workgroupValue={userOrg} translate_loading={translateLoading}/>
        </Box>
      </Box>
    </>
  );
}

export default TextTranslate;
